


    <script>
      import Navbar from "@/components/navbar0";
      import Pricing from "@/components/pricing2_min";
      import Footer from "@/components/footer";

      import store from "@/store/store";
      import {FacebookIcon, InstagramIcon, LinkedinIcon, MailIcon, TwitterIcon, CopyIcon} from "vue-feather-icons";


      export default {
        name: "referral",

        data() {
          return {


            promoCode: '',



            file:'',
            id: 1,
            checkUpload: {
              user_id: 1,
              file_type: 'pdf',
            },
            imgUrl:'',
            user: {
              user_id: 1,
              first_name: '',
              last_name: '',
              email: '',
              company: '',
            },
            showUrlImg: true,

            input2sql: {
              user_id: 1,
            },

            orders: [],
            expiration_date_milis: 0,

            total_balance: 0,

            item: {
              title: 'Доступ по скидке',
              price:300,
              quantity:1,
            },

            add_cash_sql_in: {
              user_email: '',
              user_id: 0,
              service_name: 'Депозит Skillrank',
              description: 'Депозит Skillrank',
              price: 0,
              price_rub: 0,
              quantity: 1,
            },

            /* members: [
               {first_name:'igor',last_name:'gon'},
               {first_name:'anton',last_name:'ve'}
             ],*/

            account_statistics: {
              numberOfResumesInSearch: 0,
              numberOfResumesViewed: 0,
              numberOfResumesSaved: 0,
              numberOfEmailsSent: 0,
            },

            pricingDataMonth: [
              {
                title: "Пробный доступ",
                price: 0,
                quantity: 1,
                period: "мес",
                feature: [
                  "10 просмотров резюме",
                  "5 писем рассылки",
                  "Аналитика по рассылке",
                ],
                button: "Получить",
                isBest: true,
              },
              {
                title: "Полный Доcтуп",
                price: 7990,
                quantity: 1,
                period: "мес",
                feature: [
                  "Полный доступ к базе резюме",
                  "Неограниченные рассылки",
                  "Аналитика по рассылкам",
                  "Консультации рекрутера",
                ],
                button: "Купить",
                isBest: false,
              },
              {
                title: "Интеграция",
                price: 9990,
                quantity: 1,
                period: "мес",
                feature: [
                  "Полный доступ и интеграция с ATS",
                  "Неограниченные рассылки",
                  "Аналитика по рассылкам",
                  "Консультации рекрутера",
                  "Интеграция с ATS или CRM"
                ],
                button: "Купить",
                isBest: false,
              },
            ],
            pricingDataYear: [
              {
                title: "Пробный доступ",
                price: 0,
                quantity: 12,
                period: "мес",
                feature: [
                  "10 просмотров резюме",
                  "5 писем рассылки",
                  "Аналитика по рассылке",
                ],
                button: "Получить",
                isBest: true,
              },
              {
                title: "Полный Доcтуп",
                price: 5990,
                quantity: 12,
                period: "мес",
                feature: [
                  "Полный доступ к базе резюме",
                  "Неограниченные рассылки",
                  "Аналитика по рассылкам",
                  "Консультации рекрутера",
                ],
                button: "Купить",
                isBest: false,
              },
              {
                title: "Интеграция",
                price: 7490,
                quantity: 12,
                period: "мес",
                feature: [
                  "Полный доступ и интеграция с ATS",
                  "Неограниченные рассылки",
                  "Аналитика по рассылкам",
                  "Консультации рекрутера",
                  "Интеграция с ATS или CRM"
                ],
                button: "Купить",
                isBest: false,
              },
            ],
            in_promo: {
              user_id:0,
              promo_code: '',
            },

            pay_in_sql: {
              user_email: '',
              user_id: 0,
              service_name: '',
              description: '',
              price: 0,
              quantity: 0,
            },

          };
        },
        mounted() {
          this.promoCodeGenerate();
        },
        methods: {

          promoCodeGenerate() {

            this.in_promo.user_id = store.state.user_id;
            this.axios({
              method: 'post',
              url: '' + this.$hostname + '/promo/generate-promo-code?access_token=' + store.state.token,
              data: this.in_promo,
            }).then((res) => {
              this.promoCode = res.data.promo_code;
              console.log(res.data);
            });


          },


          copyPromoFlag(x) {
            navigator.clipboard.writeText(x);

            this.$vs.notify({
              time: 4000,
              title: 'Код скопирован',
              text: 'Можно переслать коллеге',
              color: 'success'
            });

          },

          applyPromo() {
            this.axios({
              method: 'post',
              url: '' + this.$hostname + '/promo/apply-promo-code?access_token=' + store.state.token,
              data: this.in_promo,
            }).then((res) => {
              if (res.data.promo_code_status) {
                this.goToTinkov(this.item.title,this.item.price,this.item.quantity);
              } else {
                this.$vs.notify({
                  time: 4000,
                  title: 'Код неверный',
                  text: 'проверьте код и введите снова',
                  color: 'danger'
                });
              }

            });

          },

          goToTinkov(title,price,quantity) {

            this.pay_in_sql.user_email = store.state.username;
            this.pay_in_sql.user_id = store.state.user_id;
            this.pay_in_sql.price = price*100;
            this.pay_in_sql.quantity = quantity;
            let x = (quantity===1) ? 'на 7 дней' : 'на 1 год'
            this.pay_in_sql.service_name = title + ' Skillrank ' + x;
            //this.pay_in_sql.description = 'service_'+price+'_'+quantity;
            this.pay_in_sql.description = title + ' Skillrank ' + x;
            console.log(this.pay_in_sql);
            this.axios({
              method: 'post',
              url: '' + this.$hostname + '/payment/init?access_token=' + store.state.token,
              data: this.pay_in_sql,
            }).then((res) => {
              console.log(res.data)
              window.open(res.data, '_blank');
            });

          }
        },

        components: {
          Navbar,
          Pricing,
          Footer,
          FacebookIcon,
          InstagramIcon,
          TwitterIcon,
          LinkedinIcon,
          MailIcon,
          CopyIcon
        },





      }

    </script>
    <template>
    <div>
      <Navbar />
      <section class="section">

        <!--end container-->

        <div class="container mt-50 mt-30">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5">
            <img
                src="images/illustrator/analyze_report_4.svg"
                class="mr-md-4"
                alt=""
            />
          </div>

          <!--end col-->





          <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-5">
              <h4 class="title mb-4">Оптимизируйте издержки ваших коллег</h4>
              <p class="text-muted">
                Поделитесь промокодом: ваш коллега получит скидку на недельную подписку, а вы 3 дня бесплатного доступа
              </p>
              <br>
              Промокод:
              <span class="row"> <h1 class="justify-content-center">{{promoCode}}</h1>
                <a @click="copyPromoFlag(promoCode)" class="btn btn-icon btn-pills btn-secondary mt-3 ml-3"><copy-icon class="fea icon-sm"></copy-icon></a></span>
              Ссылка:
              <span class="row"> <h4 class="justify-content-center">https://skillrank.ru/promo/{{promoCode}}</h4>
              <a @click="copyPromoFlag('https://skillrank.ru/promo/'+promoCode)" class="btn btn-icon btn-pills btn-secondary ml-3"><copy-icon class="fea icon-sm"></copy-icon></a></span>
              <!-- <div class="alert alert-secondary" role="alert">  {{promoCode}} </div> -->



              <div class="col mt-4 pt-2" id="subscribe-forms">

                  <div class="p-4">
                    <form>
                      <div class="form-group mb-0 position-flexible">
                        <div class="input-group">
                          <input
                              name="referral"
                              id="referral1"
                              type="referralInput"
                              class="form-control"
                              placeholder="Ваш промокод :"
                              required
                              aria-describedby="newssubscribebtn"
                              v-model="in_promo.promo_code"
                          />
                          <div class="input-group-append">
                            <!--<a class="btn btn-primary" @click="goToTinkov(item.title,item.price,item.quantity)" >-->
                            <a class="btn btn-primary" @click="applyPromo" >
                              Применить промокод
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                    <!--end form-->
                  </div>

              </div>

             <!-- <a class="btn btn-primary" @click="goToTinkov(item.title,item.price,item.quantity)" >
                Применить промокод
              </a> -->




            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
        </div>
        <!--end container-->





        <!--end row-->

        <!--end container-->
      </section>

      <p class="text-lg-center mt-6"  > Подробнее:</p>

        <div class="container mt-35 mt-60">
          <div class="row justify-content-center" id="counter">
            <div class="col-md-4 mt-4 pt-2">

              <div class="counter-box text-center px-lg-4">

                <span class="h1 text-primary mr-2">
                <i class="uim uim-comment-alt"></i>
                </span>


                <h5 class="counter-head">Поделитесь промокодом с коллегой</h5>
                <p class="text-muted mb-0">
                  Поделитесь скдиочным промокодом с коллегой.
                </p>
              </div>
              <!--end counter box-->
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 pt-2">
              <div class="counter-box text-center px-lg-4">
                <span class="h1 text-primary mr-2">
                <i class="uim uim-wrap-text"></i>
                </span>
                <h5 class="counter-head">Используйте промокод</h5>
                <p class="text-muted mb-0">
                  При вводе промокода в соответсвующем окне ваш коллега получит скикду.
                </p>
              </div>
              <!--end counter box-->
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 pt-2">
              <div class="counter-box text-center px-lg-4">
                <span class="h1 text-primary mr-2">
                <i class="uim uim-bag"></i>
                </span>
                <h5 class="counter-head">Получите дополнительный доступ к базе</h5>
                <p class="text-muted mb-0">
                  Если ваш промокод будет использован при оплате, вы автоматически получите дополнительные 3 дня полного доступа к платформе.
                </p>
              </div>
              <!--end counter box-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->

      <!--end section-->
      <!-- counter End -->

      <!-- Testimonial Start -->
      <section class="section">

        <!--end container-->

        <div class="container mt-100 mt-60">

          <!--end row-->


          <!--end row-->
        </div>
        <!--end container-->





          <!--end row-->

        <!--end container-->
      </section>
      <!--end section-->
      <!-- Testimonial End -->
      <!--end section-->
      <!-- Footer Start -->

      <!--end footer-->

      <!--end footer-->
      <!-- Footer End -->
      <Footer />
      <!-- Back to top -->

      <!-- Back to top -->
    </div>
  </template>

